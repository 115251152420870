import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

import UserContext from '../../context/UserContext';
import Loading from './Loading';
import Error from './Error';
import CreateAccountForm from './CreateAccountForm';
import { Heading, Lede, Text } from './AreaTypography';
import { animations } from '../../utils/styles';

const CREATE_CUSTOMER = gql`
  mutation($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      email
      github {
        username
        subscription
        pullRequests {
          id
        }
      }
      shopify {
        id
        codes {
          code
          used
        }
      }
    }
  }
`;

const ContentForCustomerWithNoAccountRoot = styled(`div`)`
  animation: ${animations.simpleEntry};
`;

const ContentForCustomerWithNoAccount = () => (
  <UserContext.Consumer>
    {({ customer, profile, updateCustomer }) => (
      <ContentForCustomerWithNoAccountRoot>
        <Mutation
          mutation={CREATE_CUSTOMER}
          onCompleted={data => updateCustomer(data.createCustomer)}
        >
          {(createCustomer, { loading, error, data }) => {
            if (error) return <Error error={error.message} />;
            if (loading) return <Loading />;

            return (
              <>
                <Heading>
                  You’re made an amazing step <strong>@{profile.nickname}</strong>!
                </Heading>
                <Lede>
                  You are  about
                  to run your first requests. 💪💜
                </Lede>
                <Text>
                  In order to run your request on your system of records we have to connect to your cloud!
                </Text>
                <CreateAccountForm
                  profile={profile}
                  onSubmit={userData => async e => {
                    e.preventDefault();
                    createCustomer({
                      variables: {
                        input: {
                          githubUsername: userData.username,
                          sor_password: userData.sor_password,
                          email: userData.email,
                          firstName: userData.first_name,
                          instanceURL: userData.instance_url,
                          acceptsMarketing: userData.subscribe
                        }
                      }
                    });
                  }}
                />
              </>
            );
          }}
        </Mutation>
      </ContentForCustomerWithNoAccountRoot>
    )}
  </UserContext.Consumer>
);

export default ContentForCustomerWithNoAccount;
