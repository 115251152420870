import React from 'react';
import { colors } from '../utils/styles';

export default ({ blue }) => {
  const strokeColor = blue || colors.brand;

  return (
    <svg
      width="16"
      height="32"
      viewBox="0 0 16 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4H8V0H0V4Z"
        transform="translate(12 32) rotate(180)"
        fill={strokeColor}
      />
      <path
        d="M12.29 13.1637V2.48855C12.29 1.90021 12.7552 1.42857 13.32 1.42857C13.8848 1.42857 14.35 1.90021 14.35 2.48855V13.1637C14.35 13.7521 13.8848 14.2237 13.32 14.2237C12.7552 14.2237 12.29 13.7521 12.29 13.1637ZM4.22 13.9433C3.77322 14.3955 3.15477 14.676 2.47 14.676C1.10281 14.676 0 13.5579 0 12.1874V10.0583C0 8.68784 1.10281 7.56973 2.47 7.56973C2.84794 7.56973 3.20567 7.65517 3.52549 7.80777C3.6979 6.60604 4.72419 5.67766 5.97 5.67766C6.65477 5.67766 7.27322 5.95814 7.72 6.41034C8.16678 5.95814 8.78523 5.67766 9.47 5.67766C9.98168 5.67766 10.4563 5.83427 10.85 6.10211V2.48855C10.85 1.1181 11.9528 0 13.32 0C14.6872 0 15.79 1.1181 15.79 2.48855V13.1637C15.79 14.5342 14.6872 15.6523 13.32 15.6523C12.2874 15.6523 11.4056 15.0145 11.0359 14.1119C10.6101 14.4641 10.0651 14.676 9.47 14.676C8.78523 14.676 8.16678 14.3955 7.72 13.9433C7.27322 14.3955 6.65477 14.676 5.97 14.676C5.28523 14.676 4.66678 14.3955 4.22 13.9433Z"
        transform="translate(15.79 1) scale(-1 1)"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.02966L14.45 0V4.25824C14.45 4.25824 14.45 8.51648 13.05 9.9359L10.25 12.7747V15.6136H6H4.2L4.3 15.7427V12.7747L1.5 9.9359C0.1 8.51648 0.1 4.25824 0.1 4.25824V2.83883"
        transform="translate(15.12 9.28403) scale(-1 1)"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 9.46181C0.784 9.46181 0 8.66694 0 7.68755V1.77427C0 0.794872 0.784 0 1.75 0C2.716 0 3.5 0.794872 3.5 1.77427V7.68755C3.5 8.66694 2.716 9.46181 1.75 9.46181ZM5.6 13.2474C4.634 13.2474 3.85 12.4525 3.85 11.4731V7.45192C3.85 6.47253 4.634 5.67766 5.6 5.67766C6.566 5.67766 7.35 6.47253 7.35 7.45192V11.4731C7.35 12.4525 6.566 13.2474 5.6 13.2474ZM9.1 13.2474C8.134 13.2474 7.35 12.4525 7.35 11.4731V7.45192C7.35 6.47253 8.134 5.67766 9.1 5.67766C10.066 5.67766 10.85 6.47253 10.85 7.45192V11.4731C10.85 12.4525 10.066 13.2474 9.1 13.2474ZM12.6 13.2474C11.634 13.2474 10.85 12.4525 10.85 11.4731V9.344C10.85 8.36461 11.634 7.56973 12.6 7.56973C13.566 7.56973 14.35 8.36461 14.35 9.344V11.4731C14.35 12.4525 13.566 13.2474 12.6 13.2474Z"
        transform="translate(0.770004 1.71429)"
        fill="#fff"
        stroke={strokeColor}
        strokeWidth="1.44"
      />
      <path
        d="M1.60739 10.4415C0.979466 9.80487 0.576288 8.78294 0.316013 7.46352C0.177044 6.75904 0.0884859 6.01082 0.0393515 5.26359C0.0221996 5.00274 0.0111616 4.76027 0.00501072 4.542C0.00126977 4.40925 0 4.3127 0 4.25824V0H1.44V4.25824C1.44 4.43504 1.44902 4.75494 1.47625 5.1691C1.52161 5.85902 1.60316 6.54796 1.72879 7.18483C1.93891 8.25002 2.25253 9.04495 2.63261 9.4303L5.64 12.4794V15.6136H4.2V13.0701L1.60739 10.4415ZM11.59 15.7427H10.15V12.4794L13.1574 9.4303C13.5375 9.04495 13.8511 8.25002 14.0612 7.18483C14.1868 6.54796 14.2684 5.85902 14.3138 5.1691C14.341 4.75494 14.35 4.43504 14.35 4.25824V2.83883H15.79V4.25824C15.79 4.3127 15.7887 4.40925 15.785 4.542C15.7788 4.76027 15.7678 5.00274 15.7506 5.26359C15.7015 6.01082 15.613 6.75904 15.474 7.46352C15.2137 8.78294 14.8105 9.80487 14.1826 10.4415L11.59 13.0701V15.7427Z"
        transform="translate(0.0500031 9.28403)"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2338 7.09707C7.2338 5.13686 5.6672 3.54853 3.7338 3.54853H5.7162C6.6822 3.54853 7.4662 2.75366 7.4662 1.77427C7.4662 0.794872 6.6822 0 5.7162 0H1.75C0.784 0 0 0.794872 0 1.77427"
        transform="translate(0.770004 11.413)"
        fill="#fff"
      />
      <path
        d="M8.6738 7.81707C8.6738 8.21472 8.35145 8.53707 7.9538 8.53707C7.55616 8.53707 7.2338 8.21472 7.2338 7.81707C7.2338 6.2523 5.98732 4.98853 4.4538 4.98853C3.4938 4.98853 3.4938 3.54853 4.4538 3.54853H6.4362C7.00232 3.54853 7.4662 3.07822 7.4662 2.49427C7.4662 1.91031 7.00232 1.44 6.4362 1.44H2.47C1.90388 1.44 1.44 1.91031 1.44 2.49427C1.44 2.89191 1.11765 3.21427 0.72 3.21427C0.322355 3.21427 0 2.89191 0 2.49427C0 1.11943 1.10412 0 2.47 0H6.4362C7.80208 0 8.9062 1.11943 8.9062 2.49427C8.9062 3.51639 8.29594 4.39734 7.42155 4.78209C8.19501 5.5561 8.6738 6.6304 8.6738 7.81707Z"
        transform="translate(0.0500031 10.693)"
        fill={strokeColor}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="16"
        height="28"
      >
        <path
          d="M0 27.318H15.75V0H0V27.318Z"
          transform="translate(0.0700073 1.00366)"
          fill="#fff"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.129H8.4V0H0V4.129Z"
          transform="translate(3.57001 23.6077)"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4.129H8.4V0H0V4.129Z"
          transform="translate(3.57001 23.6077)"
          stroke={strokeColor}
          strokeWidth="1.7"
        />
      </g>
    </svg>
  );
};
