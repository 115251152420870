import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Logo from './Logo';
import { Link } from 'gatsby';
import { Button as BaseButton } from '../shared/Buttons';
import support from "../../assets/group.png";
import Kitchen from '../Kitchen';
import { breakpoints, colors, dimensions, spacing, fonts } from '../../utils/styles';
import { PopupText,openPopupWidget } from "react-calendly";
import { Heading, TextContainer, Text } from '../shared/Typography';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import createBillingDashboardSession from '../../utils/createBillingDashboardSession';
import { login, logout, isAuthenticated, getUserInfo } from "../../utils/auth"
import UserContext from '../../context/UserContext';



const CALButton = styled(BaseButton)`
  margin: ${spacing.lg}px 0 ${spacing.xl}px 0;
`;

const Lede = styled(Text)`
  font-size: 1.25rem;
  line-height: 1.4;
`;

const Row = styled(`span`)`
  display: inline-block;
  flex-shrink: 0;
  line-height: 1.3;
  padding-bottom: ${spacing['2xs']}px;
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    line-height: 1.4;
    padding-bottom: 0;
  }
`;

const Spacer = styled(`span`)`
  display: none;

  @media (min-width: ${breakpoints.desktop}px) {
    display: inline-block;
    padding: 0 ${spacing.sm}px;
  }
`;



const HeaderRoot = styled('header')`
  align-items: center;
  background-color: ${colors.lightest};
  border-bottom: 1px solid ${colors.brandLight};
  box-sizing: border-box;
  display: ${props => (props.isCovered ? 'none' : 'flex')};
  height: ${dimensions.headerHeight};
  justify-content: space-between;
  left: 0;
  padding-left: ${spacing.md}px;
  padding-right: ${spacing['3xl']}px;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 1000;

  @media (min-width: ${breakpoints.desktop}px) {
    &.covered {
      display: none;
    }
  }
`;

const HomeLink = styled(Link)`
  display: block;
  flex-shrink: 0;
  line-height: 1;
  margin-right: auto;
`;

//const { user } = useIdentityContext();


const startBillingDashboard = ({stripeId}) => {
  createBillingDashboardSession(stripeId)
      .then(res => res.json())
      .then(data => navigate(data.customer.url))
      .catch(err => console.error(err));
};

const initializeUser = () => {
  const user  = getUserInfo();
  const full_name = user?.nickname || "";
  const  stripeId = user?.app_metadata?.stripeId || "cus_IhZkmJHgnKKlok";
  return stripeId;
  };

class Header extends Component {
  state = {
    className: ''
  };
  componentDidUpdate(prevProps) {
    if (this.props.isDesktopViewport) {
      const imageBrowserStatusChanged =
        this.props.productImagesBrowserStatus !==
        prevProps.productImagesBrowserStatus;

      if (imageBrowserStatusChanged) {
        if (this.props.productImagesBrowserStatus === 'open') {
          setTimeout(() => {
            this.setState({
              className: 'covered'
            });
          }, 500);
        } else {
          this.setState({
            className: ''
          });
        }
      }
    }
  }

  render() {
    
    const stripeId=initializeUser();
    const { className } = this.state;
    const url = "https://calendly.com/icloudready/request-discovery"
    return (
      <>
       {/* <UserContext.Consumer> {({ profile })} */}
      <HeaderRoot className={className}>{/*   <HomeLink to="home" aria-label="home">   <Logo /> </HomeLink> */}
        <Row><Text>Welcome to IcloudReady! you have started your{' '}</Text>
          <HomeLink to="./" onClick={e => startBillingDashboard({ stripeId })}>30 day trial</HomeLink>
        </Row>
          <img src={support} alt="support" />
        <Row><Text>Our architects are here to help</Text>
          <HomeLink  to="./" onClick={e => openPopupWidget({ url })}>Book a free session</HomeLink>
        {/*<Link to="/booking" target="blank">Book a free session</Link> */}
        </Row>
        <Row>
       
        </Row>
                <Row>
       
        </Row>
      </HeaderRoot>
       {/* </UserContext.Consumer> */}
       </>
    );
  }
}

Header.propTypes = {
  productImagesBrowserStatus: PropTypes.string.isRequired,
  isDesktopViewport: PropTypes.bool
};

export default Header;
