import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button as BaseButton } from '../shared/Buttons';
import OpenIssues from './OpenIssues';
import { Heading, Lede, Text } from './AreaTypography';
import { spacing, animations } from '../../utils/styles';

const ContentForNotCustomerRoot = styled(`div`)`
  animation: ${animations.simpleEntry};
`;

const Button = styled(BaseButton)`
  margin: ${spacing.lg}px 0 ${spacing.xl}px 0;
`;

class ContentForNotCustomer extends Component {
  state = {
    issuesVisible: false
  };

  showIssuesList = () => {
    this.setState({
      issuesVisible: true
    });
  };

  render() {
    const { issuesVisible } = this.state;
    const {
      profile: { nickname }
    } = this.props;

    return (
      <ContentForNotCustomerRoot>
        <Heading>Hi, @{nickname}!</Heading>
        <Lede>
        Let’s get you started with your first Request Run with Icloudready!
        </Lede>
        <Text>
        Once you’ve moved on with you request run, you achieve more and more poits and level in the digital transfomatin heros
        </Text>
        <Text>
        If you have questions, ask on any issue (you can tag @icloudready/digitalHeros
            if you’d like) or hit us up{' '}
          <a href="https://twitter.com/icloudready">on Twitter at @icloudready</a>.
        </Text>

        {!issuesVisible ? (
          <>
            <Text>
              Click the button below for requests we suggest you need to start running !.
            </Text>
            <Button onClick={this.showIssuesList} inverse>
              Explore our top suggested requests
            </Button>
          </>
        ) : (
          <OpenIssues />
        )}
      </ContentForNotCustomerRoot>
    );
  }
}

ContentForNotCustomer.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ContentForNotCustomer;
