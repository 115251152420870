import React from 'react';
import ApolloClient from 'apollo-boost';
import { getAccessToken } from '../utils/auth';
import axios from "axios"

const url = process.env.IDENGAGER_API

export const isBrowser = typeof window !== 'undefined';

export const idengager = isBrowser
  ? new ApolloClient({
      uri: `${process.env.IDENGAGER_API}/graphql`,
      request: operation => {
        operation.setContext({
          headers: {
            "Idengager-Application-Id": `sample`,
            "Idengager-Master-Key": `sampleMaster`
          }
        });
      }
    })
  : {};

  export const defaultIdengagerContext = {
    idengager,
    isCartOpen: false,
    isKitchenOpen: false,
    adding: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    addVariantToCart: () => {},
    addVariantToKitchen: () => {},
    removeLineItem: () => {},
    getRunResult: () => {},
    updateLineItem: () => {}
  };
   export function run(requestId) {
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      
    })
    .catch(function (error) {
      console.log(error);
    });
}


export async function run2(mynickname) {
try {
  const { data } = await idengager.mutate({
    mutation: gql`
        mutation CreateObject ($mynickname: String!){
          createCar(input: {fields: {nickname: $mynickname}}){
    car{
      id
      createdAt
              }
            }
          }
        `,
variables: { mynickname: inputnickname }
})}
catch(error){
  console.log("err....", error)
}
}
  const IdengagerContext = React.createContext(defaultIdengagerContext);
  

  export const withIdengagerContext = Component => {
    return props => (
      <IdengagerContext.Consumer>
        {context => <Component {...props} idengagerContext={context} />}
      </IdengagerContext.Consumer>
    );
  };
  
  export default IdengagerContext;
  