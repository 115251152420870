import React from 'react';
import styled from '@emotion/styled';
import { SiOracle } from 'react-icons/si';
import { SiDynamics365 } from 'react-icons/si';

import { login } from '../../utils/auth';
import { spacing, animations } from '../../utils/styles';
import { Button as BaseButton } from '../shared/Buttons';
import { Heading, SectionHeading, Text } from './AreaTypography';

const ContentForGuestRoot = styled(`div`)`
  animation: ${animations.simpleEntry};
  position: relative;
`;

const FirstHeading = styled(Heading)`
  padding-right: ${spacing.lg}px;
  margin-right: 15px;
`;

const Button = styled(BaseButton)`
  margin: ${spacing.lg}px 0 ${spacing.xl}px 0;
`;

const ContentForGuest = () => (
  <ContentForGuestRoot>
    <SectionHeading>Already have an Oracle/Microsoft Cloud Account</SectionHeading>
    <FirstHeading>
    Take your pick and run it NOW…for <strong>FREE!</strong>
    </FirstHeading>
    <Text>
    How about a free health check first to make sure all’s good? Log in with your Oracle cloud account and start your amazing journey.

    </Text>
    <Button inverse onClick={e => login()}>
      Log in with Your Oracle Cloud Applications<SiOracle />
    </Button>
    <SectionHeading>Don’t have an ERP yet? No worries, we’ve got you. </SectionHeading>
    <Heading>Get a demo ERP/CRM account from your selected vendor ?</Heading>
    <Text>
    You can have your first run through your demo account, and once you’ve acquired your chosen system of records, come back here to sign in and start running your request. 
    </Text>
    <Button
      inverse
      href="https://www.oracle.com/product-tours/">
      Explore System of records options
    </Button>
    <Text style={{ fontStyle: 'italic' }}>
      We don't own or control any of your system of records, our platform works with any of support system of records. 
      Please view{' '}
      <a href="https://doc-archives.microstrategy.com/producthelp/11.0/Readme/content/certified_configurations.htm">
        the list of supported system of records
      </a>{' '}
    </Text>
  </ContentForGuestRoot>
);

export default ContentForGuest;
