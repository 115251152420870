import React from 'react';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';

import { navigate } from 'gatsby';

import { client } from '../../context/ApolloContext';
//import { kitchenClient } from '../../context/KitchenContext';
import { idengager } from '../../context/IdengagerContext';
import StoreContext, { defaultStoreContext } from '../../context/StoreContext';
import KitchenContext, { defaultKitchenContext } from '../../context/KitchenContext';
import UserContext, { defaultUserContext } from '../../context/UserContext';
import InterfaceContext, {
  defaultInterfaceContext
} from '../../context/InterfaceContext';

import Header from './Header';
import CustomerArea from '../CustomerArea';
import PageContent from './PageContent';
import ProductImagesBrowser from '../ProductPage/ProductImagesBrowser';
import Cart from '../Cart';
import Kitchen from '../Kitchen';
import SiteMetadata from '../shared/SiteMetadata';

import { logout, getUserInfo } from '../../utils/auth';
import { breakpoints, colors } from '../../utils/styles';

// Import Futura PT typeface
import '../../fonts/futura-pt/Webfonts/futurapt_demi_macroman/stylesheet.css';
import gql from 'graphql-tag';

const globalStyles = css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
    color: ${colors.text};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 1.375;
    margin: 0 auto;
  }
`;

const Viewport = styled(`div`)`
  width: 100%;
`;

export default class Layout extends React.Component {
  desktopMediaQuery;

  state = {
    /**************** Interface Context Driven by our customer APIs */
    interface: {
      ...defaultInterfaceContext,
      toggleCart: () => {
        this.setState(state => ({
          interface: {
            ...state.interface,
            customerAreaStatus:
              state.interface.isDesktopViewport === false &&
                state.interface.customerAreaStatus === 'open'
                ? 'closed'
                : state.interface.customerAreaStatus,
            cartStatus:
              this.state.interface.cartStatus === 'open' ? 'closed' : 'open'
          }
        }));
      },
      toggleKitchen: () => {
        this.setState(state => ({
          interface: {
            ...state.interface,
            customerAreaStatus:
              state.interface.isDesktopViewport === false &&
                state.interface.customerAreaStatus === 'open'
                ? 'closed'
                : state.interface.customerAreaStatus,
            kitchenStatus:
              this.state.interface.kitchenStatus === 'open' ? 'closed' : 'open'
          }
        }));
      },
      toggleProductImagesBrowser: img => {
        this.setState(state => ({
          interface: {
            ...state.interface,
            productImagesBrowserStatus: img ? 'open' : 'closed',
            productImageFeatured: img
              ? img
              : state.interface.productImageFeatured
          }
        }));
      },
      featureProductImage: img => {
        this.setState(state => ({
          interface: {
            ...state.interface,
            productImageFeatured: img
          }
        }));
      },
      setCurrentProductImages: images => {
        this.setState(state => ({
          interface: {
            ...state.interface,
            currentProductImages: images,
            productImageFeatured: null
          }
        }));
      },
      toggleCustomerArea: () => {
        this.setState(state => ({
          interface: {
            ...state.interface,
            customerAreaStatus: this.toggleCustomerAreaStatus()
          }
        }));
      }
    },
    /**************** User Context Driven by our Auth0 SDK */
    user: {
      ...defaultUserContext,
      handleLogout: () => {
        this.setState({
          user: {
            ...defaultUserContext,
            loading: false
          }
        });
        logout(() => navigate('/'));
      },
      //ui operations
      updateCustomer: data => {
        this.setState(state => ({
          user: {
            ...state.user,
            customer: data,
            loading: false
          }
        }));
      }
    },
    /**************** Store Run Context Driven by our marketplace js-buy-sdk https://github.com/Shopify/js-buy-sdk */
    store: {
      ...defaultStoreContext,
      addVariantToCart: (variantId, quantity) => {
        if (variantId === '' || !quantity) {
          console.error('Both a size and quantity are required.');
          return;
        }

        this.setState(state => ({
          store: {
            ...state.store,
            adding: true
          }
        }));

        const { checkout, client, idengager } = this.state.store;
        const checkoutId = checkout.id;
        const lineItemsToUpdate = [
          { variantId, quantity: parseInt(quantity, 10) }
        ];

        return client.checkout
          .addLineItems(checkoutId, lineItemsToUpdate)
          .then(checkout => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout,
                adding: false
              }
            }));
          });
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        //check shpoify-buy sdk client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then((checkout) =>
        return client.checkout
          .removeLineItems(checkoutID, [lineItemID])
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res
              }
            }));
          });
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [
          { id: lineItemID, quantity: parseInt(quantity, 10) }
        ];

        return client.checkout
          .updateLineItems(checkoutID, lineItemsToUpdate)
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res
              }
            }));
          });
      }
    },
      /**************** Kitchen Context Driven by our marketplace js-buy-sdk https://github.com/Shopify/js-buy-sdk */
      kitchen: {
        ...defaultKitchenContext,
        addVariantToKitchen: (variantId, quantity) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
          }

          this.setState(state => ({
            kitchen: {
              ...state.kitchen,
              adding: true
            }
          }));

          const { checkout, kitchenClient, idengager } = this.state.kitchen;
          const checkoutId = checkout.id;
          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) }
          ];

          return kitchenClient.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(checkout => {
              this.setState(state => ({
                kitchen: {
                  ...state.kitchen,
                  checkout,
                  adding: false
                }
              }));
            });
        },
        removeLineItem: (kitchenClient, checkoutID, lineItemID) => {
          //check shpoify-buy sdk client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then((checkout) =>
          return kitchenClient.checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then(res => {
              this.setState(state => ({
                store: {
                  ...state.store,
                  checkout: res
                }
              }));
            });
        },
        getRunResult: (kitchenClient, checkoutID, lineItemID) => {
          return kitchenClient.checkout
            .getRunResults(checkoutID, [lineItemID])
            .then(res => {
              this.setState(state => ({
                kitchen: {
                  ...state.kitchen,
                  checkout: res
                }
              }));
            });
        },
        updateLineItem: (kitchenClient, checkoutID, lineItemID, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemID, quantity: parseInt(quantity, 10) }
          ];

          return kitchenClient.checkout
            .updateLineItems(checkoutID, lineItemsToUpdate)
            .then(res => {
              this.setState(state => ({
                kitchen: {
                  ...state.kitchen,
                  checkout: res
                }
              }));
            });
        }
      }
    };

    /**************** Async part of Store Run Context Driven by our marketplace js-buy-sdk https://github.com/Shopify/js-buy-sdk */
    async initializeCheckout() {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('run_checkout_id')
        : null;

      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('run_checkout_id', checkout.id);
        }

        this.setState(state => ({
          store: {
            ...state.store,
            checkout
          }
        }));
      };

      const createNewCheckout = () => this.state.store.client.checkout.create();
      const fetchCheckout = id => this.state.store.client.checkout.fetch(id);

      if (existingCheckoutID && existingCheckoutID !== 'null') {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);
            
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
          setCheckoutInState(checkout);
         /* {({ addVariantToCart }) => (
            <Form onSubmit={this.handleSubmit(addVariantToCart)} noValidate>*/
            return;
          }
        } 
      catch (e) {
          localStorage.setItem('run_checkout_id', null);
        }
      }

      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    }

  /**************** Async part of kitchen Run Context Driven by our marketplace js-buy-sdk https://github.com/Shopify/js-buy-sdk */
  async initializeKitchenCheckout() {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';
      const existingKitchenCheckoutID = isBrowser
        ? localStorage.getItem('kitchen_checkout_id')
        : null;

      const setKitchenCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('kitchen_checkout_id', checkout.id);
        }

        this.setState(state => ({
          kitchen: {
            ...state.kitchen,
            checkout
          }
        }));
      };

      const createNewKitchenCheckout = () => this.state.kitchen.kitchenClient.checkout.create();
      const fetchKitchenCheckout = id => this.state.kitchen.kitchenClient.checkout.fetch(id);

      if (existingKitchenCheckoutID && existingKitchenCheckoutID !== 'null') {
        try {
          const kitchenCheckout = await fetchKitchenCheckout(existingKitchenCheckoutID);

          // Make sure this cart hasn’t already been purchased.
          if (!kitchenCheckout.completedAt) {
            setKitchenCheckoutInState(kitchenCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem('kitchen_checkout_id', null);
        }
      }

      const newKitchenCheckout = await createNewKitchenCheckout();
      setKitchenCheckoutInState(newKitchenCheckout);
    }
  /**************** Async part of Customer Context Driven by our icloud-ready api */

  async loadCustomer(nickname) {
      try {
        const { data } = await client.mutate({
          mutation: gql`
          mutation($user: String!) {
            updateCustomerTags(githubUsername: $user) {
              email
              github {
                username
                subscription
                pullRequests {
                  id
                }
              }
              shopify {
                id
                codes {
                  code
                  used
                }
              }
            }
          }
        `,
          variables: { user: nickname }
        });

        this.setState(state => ({
          user: {
            ...state.user,
            customer: data.updateCustomerTags,
            loading: false
          }
        }));
      } catch (error) {
        this.setState(state => ({
          user: {
            ...state.user,
            error: error.toString(),
            loading: false
          }
        }));
      }
    }
  /**************** Async part of Customer Asset Context Driven by our idengager api */

  async createCars(inputnickname) {
      try {
        const { data } = await idengager.mutate({
          mutation: gql`
            mutation CreateObject ($mynickname: String!){
              createCar(input: {fields: {nickname: $mynickname}}){
        car{
          id
          createdAt
                  }
                }
              }
            `,
          variables: { mynickname: inputnickname }
        });
        this.setState(state => ({
          car: {
            ...state.car,
            //    customer: data.updateCustomerTags,
            loading: false
          }
        }));
      } catch (error) {
        this.setState(state => ({
          car: {
            ...state.car,
            error: error.toString(),
            loading: false
          }
        }));
      }
    }

  componentDidMount() {
      // Observe viewport switching from mobile to desktop and vice versa
      const mediaQueryToMatch = `(min-width: ${breakpoints.desktop}px)`;

      this.desktopMediaQuery = window.matchMedia(mediaQueryToMatch);
      this.desktopMediaQuery.addListener(this.updateViewPortState);

      this.updateViewPortState();

      // Make sure we have a Shopify checkout created for cart management.
      this.initializeCheckout();

      // Make sure we have a kitchen checkout created for kitchen management.
      this.initializeKitchenCheckout();

      // Mounting Layout on 'callback' page triggers user 'loading' flag
      if (this.props.location.pathname === '/callback/') {
        this.setState(state => ({
          user: { ...state.user, loading: true }
        }));
      }

      // Make sure to set user.profile when a visitor reloads the app
      if (this.props.location.pathname !== '/callback/') {
        this.setUserProfile();
      }
    }

  componentDidUpdate(prevProps) {
      // Set user.profile after redirection from '/callback/' to '/'
      if (
        prevProps.location.pathname !== this.props.location.pathname &&
        prevProps.location.pathname === '/callback/'
      ) {
        this.setState(state => ({
          interface: {
            ...state.interface,
            customerAreaStatus: 'open'
          }
        }));
        this.setUserProfile();
      }

      // Close product modal window after navigating "back"
      if (
        prevProps.location.pathname !== this.props.location.pathname &&
        prevProps.location.pathname.startsWith('/product/')
      ) {
        this.setState(state => ({
          interface: {
            ...state.interface,
            productImagesBrowserStatus: 'closed'
          }
        }));
      }
    }

  componentWillUnmount = () => {
      this.desktopMediaQuery.removeListener(this.updateViewPortState);
    };

    updateViewPortState = e => {
      this.setState(state => ({
        interface: {
          ...state.interface,
          isDesktopViewport: this.desktopMediaQuery.matches
        }
      }));
    };

    setUserProfile = () => {
      // Load the user info from Auth0.
      const profile = getUserInfo();

      // If logged in set user.profile
      if (profile && profile.nickname) {
        this.setState(state => ({
          user: {
            ...state.user,
            profile,
            loading: true
          }
        }));

        // Call the IDengager Backend Server
        this.createCars(profile.nickname);

        // and load the customer data
        this.loadCustomer(profile.nickname);


      }
    };

    toggleCustomerAreaStatus = () => {
      if (this.state.interface.customerAreaStatus === 'initial') {
        return this.state.interface.isDesktopViewport ? 'closed' : 'open';
      } else {
        return this.state.interface.customerAreaStatus === 'closed'
          ? 'open'
          : 'closed';
      }
    };

    render() {
      const { children, location } = this.props;

      return (
        <>
          <Global styles={globalStyles} />
          <SiteMetadata />
          <UserContext.Provider value={this.state.user}>
            <StoreContext.Provider value={this.state.store}>
              <KitchenContext.Provider value={this.state.kitchen}>
                <InterfaceContext.Provider value={this.state.interface}>
                  <InterfaceContext.Consumer>
                    {({
                      isDesktopViewport,
                      kitchenStatus,
                      toggleKitchen,
                      cartStatus,
                      toggleCart,
                      customerAreaStatus,
                      toggleCustomerArea,
                      productImagesBrowserStatus,
                      currentProductImages,
                      featureProductImage,
                      productImageFeatured,
                      toggleProductImagesBrowser
                    }) => (
                      <>
                        <Header
                          isDesktopViewport={isDesktopViewport}
                          productImagesBrowserStatus={productImagesBrowserStatus}
                        />

                        <Viewport>


                          <Cart
                            isDesktopViewport={isDesktopViewport}
                            status={cartStatus}
                            toggle={toggleCart}
                            customerAreaStatus={customerAreaStatus}
                            productImagesBrowserStatus={productImagesBrowserStatus}
                          />
                          <CustomerArea
                            location={location}
                            status={customerAreaStatus}
                            toggle={toggleCustomerArea}
                            isDesktopViewport={isDesktopViewport}
                            productImagesBrowserStatus={productImagesBrowserStatus}
                          />

                          <PageContent
                            cartStatus={cartStatus}
                            kitchenStatus={kitchenStatus}
                            customerAreaStatus={customerAreaStatus}
                            isDesktopViewport={isDesktopViewport}
                            productImagesBrowserStatus={productImagesBrowserStatus}
                            location={location}
                          >
                            {children}
                          </PageContent>

                          {currentProductImages.length > 0 && (
                            <ProductImagesBrowser
                              featureProductImage={featureProductImage}
                              images={currentProductImages}
                              position={productImagesBrowserStatus}
                              imageFeatured={productImageFeatured}
                              toggle={toggleProductImagesBrowser}
                              isDesktopViewport={isDesktopViewport}
                            />
                          )}
                        </Viewport>
                      </>
                    )}
                  </InterfaceContext.Consumer>
                </InterfaceContext.Provider>
              </KitchenContext.Provider>
            </StoreContext.Provider>
          </UserContext.Provider>
        </>
      );
    }
  }
