import React from 'react';
import Client from 'shopify-buy';
import ApolloClient from 'apollo-boost';
import { getAccessToken } from '../utils/auth';
import axios from "axios"

const kitchenClient = Client.buildClient({
  domain: 'icloudready.myshopify.com',
  storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN
});


const url = process.env.IDENGAGER_API
export const isBrowser = typeof window !== 'undefined';
export const kitchenClient2 = isBrowser
  ? new ApolloClient({
      uri: `${process.env.IDENGAGER_API}/graphql`,
      request: operation => {
        operation.setContext({
          headers: {
            "Idengager-Application-Id": `sample`,
            "Idengager-Master-Key": `sampleMaster`
          }
        });
      }
    })
  : {};

export const defaultKitchenContext = {
  kitchenClient,
  isCartOpen: false,
  isKitchenOpen: false,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  addVariantToCart: () => {},
  addVariantToKitchen: () => {},
  removeLineItem: () => {},
  getRunResult: () => {},
  updateLineItem: () => {},
  run: () => {}
};


export function run(requestId) {
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    
  })
  .catch(function (error) {
    console.log(error);
  });
}

const KitchenContext = React.createContext(defaultKitchenContext);

export const withKitchenContext = Component => {
  return props => (
    <KitchenContext.Consumer>
      {context => <Component {...props} kitchenContext={context} />}
    </KitchenContext.Consumer>
  );
};

export default KitchenContext;
