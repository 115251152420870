import React from 'react';
import styled from '@emotion/styled';
import KitchenListItem from './KitchenListItem';

import { colors, spacing } from '../../utils/styles';

const KitchenListRoot = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Headers = styled(`div`)`
  border-bottom: 1px solid ${colors.brandBright};
  display: flex;
  justify-content: space-between;

  span {
    color: ${colors.textLight};
    flex-basis: 60px;
    flex-grow: 0;
    font-size: 0.8rem;
    padding-bottom: ${spacing.xs}px;
    text-align: center;

    &:first-of-type {
      flex-grow: 1;
      text-align: left;
    }
  }
`;

const KitchenList = ({
  items,
  handleRemove,
  handleRunResult,
  updateQuantity,
  setKitchenLoading,
  isKitchenLoading
}) => (
    <>
      <Headers>
        <span>Request</span>
        <span>Status.</span>
        <span>Output Link</span>
      </Headers>
      <KitchenListRoot>
        {items.map(item => (
          <KitchenListItem
            key={item.id}
            item={item}
            handleRemove={handleRemove(item.id)}
            handleRunResult={handleRunResult(item.id)}
            updateQuantity={updateQuantity(item.id)}
            setKitchenLoading={setKitchenLoading}
            isKitchenLoading={isKitchenLoading}
          />
        ))}
      </KitchenListRoot>
    </>
  );

export default KitchenList;
